import { User } from "../../firebase/types";

const usePremiumUser = () => {
  const isPremiumUser = (user: User | undefined) => {
    if (!user) return false;
    return user?.premium && user.subscriptionStatus === "active" || user.admin;
  };

  return {
    isPremiumUser,
  };
};

export default usePremiumUser;
