import { createContext, useContext, useState } from "react";

export interface CoordinatesType {
  latitude: number;
  longitude: number;
}

interface LocationServiceContextType {
  location: string | undefined;
  coordinates: CoordinatesType | undefined;
  locationPermission: "granted" | "denied" | undefined;
  requestLocationAccess: () => void;
}

const LocationServiceContext = createContext<LocationServiceContextType>({
  location: undefined,
  coordinates: undefined,
  locationPermission: undefined,
  requestLocationAccess: () => {},
});

export const useLocationService = () => useContext(LocationServiceContext);

export const LocationServiceProvider = ({ children }: any) => {
  const [location, setLocation] = useState<string>();
  const [coordinates, setCoordinates] = useState<CoordinatesType>();
  const [locationPermission, setLocationPermission] = useState<
    "granted" | "denied"
  >();

  const requestLocationAccess = () => {
    if (locationPermission === "granted") return;
    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy: false,
    });
  };

  const success = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    setCoordinates({ latitude, longitude });
    getLocationName(latitude, longitude);
    setLocationPermission("granted");
  };

  const error = (e: GeolocationPositionError) => {
    console.error(e.message);
    setLocationPermission("denied");
  };

  const getLocationName = async (latitude: number, longitude: number) => {
    try {
      const res = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const resp = await res.json();
      setLocation(resp.display_name);
    } catch (error) {
      console.error("Failed to fetch location name:", error);
    }
  };

  const value = {
    location,
    coordinates,
    locationPermission,
    requestLocationAccess,
  };

  return (
    <LocationServiceContext.Provider value={value}>
      {children}
    </LocationServiceContext.Provider>
  );
};
