import { createTheme } from "@mui/material";

export const theme = (mode: any) =>
  createTheme({
    palette: {
      mode: mode, // Use the mode state
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: "roboto",
            color: mode === "dark" ? "#fff" : "#000",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            padding: 0,
            backgroundColor: mode === "dark" ? "#121212" : "#fff",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 20,
            paddingTop: 20,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 1,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "roboto",
            // General styles for all buttons can be placed here
          },
          contained: {
            backgroundColor: "#C66427",
            color: "#FFFFFF", // Sets the text color to white
            "&:hover": {
              backgroundColor: "#e07b50", // Lighter or darker for hover
            },
            "&:active": {
              backgroundColor: "#C66427", // Same as normal state or variant for active
            },
          },
          outlined: {
            color: "#C66427", // Text color matches the background color of contained
            borderColor: "#C66427", // Border color matches the background color of contained
            "&:hover": {
              backgroundColor: "rgba(198, 100, 39, 0.04)", // Slight color for hover, very light tint
              borderColor: "#e07b50", // Optionally change the border color on hover
            },
            "&:active": {
              backgroundColor: "rgba(198, 100, 39, 0.08)", // Slightly darker tint on active
            },
          },
          text: {
            // Specific styles for text variant if needed
            "&:hover": {
              backgroundColor: "transparent", // No background on hover for text buttons
            },
            "&:active": {
              backgroundColor: "transparent", // No background on active for text buttons
            },
          },
        },
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });
