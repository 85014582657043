import { createContext, useContext, useState } from "react";

interface LoginWallContextType {
  openLoginWall: boolean;
  setOpenLoginWall: (open: boolean) => void;
}

const LoginWallContext = createContext<LoginWallContextType>({
  openLoginWall: false,
  setOpenLoginWall: () => {},
});

export const useLoginContext = () => useContext(LoginWallContext);

export const LoginWallProvider = ({ children }: any) => {
  const [openLoginWall, setOpenLoginWall] = useState<boolean>(false);

  const context = {
    openLoginWall,
    setOpenLoginWall,
  };

  return (
    <LoginWallContext.Provider value={context}>
      {children}
    </LoginWallContext.Provider>
  );
};
