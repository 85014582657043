import React from "react";
import { Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { green, red, amber, blue } from "@mui/material/colors";

type ToastProps = {
  message: string;
  type: "success" | "error" | "warning" | "info";
  bottom?: number;
};

const Toast: React.FC<ToastProps> = ({ message, type, bottom = 80 }) => {
  // Adjusted for MUI color utility usage
  const backgroundColor: Record<ToastProps["type"], string> = {
    success: green[300], // Using MUI's color system
    error: red[300],
    warning: amber[300],
    info: blue[300],
  };

  // Determine which icon to display based on the type
  const Icon = {
    success: CheckCircleOutlineIcon,
    error: ErrorOutlineIcon,
    warning: WarningAmberIcon,
    info: InfoOutlinedIcon,
  }[type];

  return (
    <Stack
      bgcolor={backgroundColor[type]}
      padding={1}
      direction={"row"}
      position="fixed"
      bottom={bottom}
      right={10}
      borderRadius={2}
      spacing={1}
      alignItems="center"
      zIndex={100000}
    >
      <Icon style={{ color: "#fff" }} />

      <Typography color="#fff" variant="body2">
        {message}
      </Typography>
    </Stack>
  );
};

export default Toast;
