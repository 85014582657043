import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Poop } from "../firebase/types";

export interface RenderingPoopsState {
  renderingPoops: Poop[];
}

const initialState: RenderingPoopsState = {
  renderingPoops: [],
};

export const renderingPoopsSlice = createSlice({
  name: "renderingPoops",
  initialState,
  reducers: {
    setRenderingPoops(state, action) {
      state.renderingPoops = [...state.renderingPoops, action.payload];
    },
    updateRenderingPoops(state, action) {
      const index = state.renderingPoops.findIndex(
        (item) => item.id === action.payload.id
      );
      state.renderingPoops[index] = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setRenderingPoops, updateRenderingPoops } =
  renderingPoopsSlice.actions;

export const getRenderingPoops = (state: RootState) =>
  state.renderingPoops.renderingPoops;

export default renderingPoopsSlice.reducer;
