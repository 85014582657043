import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
//@ts-ignore
import renderingPoopsReducer from "./renderingPoops";
import userReducer from "./user";
// import currentPoopReducer from './CurrentPoop';
// import mapScrollReducer from './MapScroll';

export const store = configureStore({
  reducer: {
    // mapScroll: mapScrollReducer,
    renderingPoops: renderingPoopsReducer,
    user: userReducer,
    // currentPoop: currentPoopReducer,
  },
});
 
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
