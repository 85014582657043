import { createContext, useContext, useEffect, useState } from "react";
import { Notification } from "../../firebase/types";
import { query, where, getDocs, orderBy, updateDoc } from "firebase/firestore";
import { NOTIFICATIONS_COLLECTION } from "../../global/utils";
import { useAuthService } from "./AuthContext";

interface NotificationsContextType {
  notifications?: Notification[];
  markAllNotificationsAsRead: () => void;
}

const NotificationsContext = createContext<NotificationsContextType>({
  notifications: [],
  markAllNotificationsAsRead: () => {},
});

export const useNotificationsService = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }: any) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const { user } = useAuthService();

  useEffect(() => {
    if (user) {
      getNotifications();
    }
  }, [user]);

  const getNotifications = async () => {
    let initialQuery = query(
      NOTIFICATIONS_COLLECTION,
      orderBy("createdDate", "desc"),
      where("uid", "==", user?.uid)
    );

    let documentSnapshots = await getDocs(initialQuery);
    let documentData: Notification[] = documentSnapshots.docs.map(
      (document) => document.data() as Notification
    );
    setNotifications(documentData);
  };

  const markAllNotificationsAsRead = async () => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      isRead: true,
    }));

    setNotifications(updatedNotifications);
    try {
      let initialQuery = query(
        NOTIFICATIONS_COLLECTION,
        where("uid", "==", user?.uid)
      );

      let documentSnapshots = await getDocs(initialQuery);

      const updatePromises = documentSnapshots.docs.map((doc) =>
        updateDoc(doc.ref, { isRead: true })
      );

      await Promise.all(updatePromises);
    } catch (error) {
      console.error("Error updating notifications: ", error);
    }
  };

  const context = {
    notifications,
    markAllNotificationsAsRead,
  };

  return (
    <NotificationsContext.Provider value={context}>
      {children}
    </NotificationsContext.Provider>
  );
};
