import { format } from "date-fns";
import { firestore } from "../firebase/init";
import { collection } from "firebase/firestore";
import { Theme, useMediaQuery } from "@mui/material";
import { useToast } from "../context/ToastContext";
import useNetwork from "./hooks/useNetwork";
import usePrevious from "./hooks/usePrevious";
import { useEffect } from "react";

export const poopGif = require("../assets/poopBouncing.gif");

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const DEFAULT_COLOR = "#B48A81";

export const FETCH_LIMIT = 5;

export const isPasswordValid = (pass: string) => pass.length > 5;

export const isEmailValid = (email: string) =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );

export const generateUUID = () => {
  let str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ";
  let uuid = [];
  for (let i = 0; i < 32; i++) {
    uuid.push(str[Math.floor(Math.random() * str.length)]);
  }
  return uuid.join("");
};

/**
 * Normalizes a timestamp into a relative time string.
 * @param {string | null} timestamp ISO 8601 string or null.
 * @returns {string} A relative time string or "now".
 */
export const normalizeTimestamp = (timestamp: string) => {
  if (!timestamp) return "Now";

  const date = new Date(timestamp);
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const interval in intervals) {
    //@ts-ignore
    if (seconds >= intervals[interval]) {
      //@ts-ignore
      const count = Math.floor(seconds / intervals[interval]);
      return `${count} ${interval}${count !== 1 ? "s" : ""} ago`; // Pluralizes the unit and adds "ago"
    }
  }

  return "Now"; // More informative for timestamps that are very recent
};

export const LIKES_COLLECTION = collection(firestore, "likes");
export const LIKES_COMMENT_COLLECTION = collection(firestore, "likesComment");
export const COMMENTS_COLLECTION = collection(firestore, "comments");
export const POOPS_COLLECTION = collection(firestore, "poops");
export const NOTIFICATIONS_COLLECTION = collection(firestore, "notifications");
export const USER_COLLECTION = collection(firestore, "users");
export const SUPPORT_COLLECTION = collection(firestore, "support");

export const handleFirebaseError = (
  errorCode: string,
  setErrorMessage?: Function
) => {
  let message;
  switch (errorCode) {
    case "auth/app-deleted":
      message = "The Firebase app was deleted.";
      break;
    case "auth/app-not-authorized":
      message = "This app is not authorized for Firebase Authentication.";
      break;
    case "auth/argument-error":
      message = "Invalid argument supplied.";
      break;
    case "auth/invalid-api-key":
      message = "Your API key is invalid.";
      break;
    case "auth/invalid-user-token":
      message = "Your session has expired. Please sign in again.";
      break;
    case "auth/network-request-failed":
      message = "Network error. Please try again.";
      break;
    case "auth/operation-not-allowed":
      message =
        "This operation is not allowed. Please enable it in the Firebase Console.";
      break;
    case "auth/requires-recent-login":
      message =
        "You need to have recently signed in to perform this operation.";
      break;
    case "auth/too-many-requests":
      message =
        "We have detected too many requests from your device. Please wait and try again later.";
      break;
    case "auth/unauthorized-domain":
      message = "This domain is not authorized for OAuth operations.";
      break;
    case "auth/user-disabled":
      message = "Your account has been disabled by an administrator.";
      break;
    case "auth/user-token-expired":
      message = "Your session has expired. Please sign in again.";
      break;
    case "auth/web-storage-unsupported":
      message =
        "Your browser does not support web storage, or you have it disabled.";
      break;
    case "auth/email-already-in-use":
      message = "This email is already in use by another account.";
      break;
    case "auth/invalid-email":
      message = "The email address is not valid.";
      break;
    case "auth/operation-not-supported-in-this-environment":
      message = "This operation is not supported in your current environment.";
      break;
    case "auth/weak-password":
      message = "Your password is too weak.";
      break;
    case "auth/wrong-password":
      message = "Your password is incorrect.";
      break;
    case "auth/invalid-credential":
      message = "Your credentials are invalid or expired.";
      break;
    default:
      message = "An unknown error occurred.";
  }
  if (setErrorMessage) {
    setErrorMessage(message);
  }

  return message;
};

const poopNamesMatrix = [
  ["Plop", "Dung", "Stink", "Squirt", "Droplet", "Blob", "Clump", "Crud"],
  ["Nugget", "Stool", "Whiff", "Doodle", "Chunk", "Splodge", "Sludge", "Glob"],
  ["Gloop", "Turdy", "Bog", "Log", "Smudge", "Drop", "Heap", "Mire"],
  ["MudPie", "Splatter", "Splat", "Puddle", "Slop", "Grime", "Slime", "Gunk"],
  ["Fudge", "Turd", "Doodoo", "Poopie", "Curd", "Smear", "Mush", "Goop"],
];

export const getRandomPoopName = () => {
  // Flatten the matrix into a single array
  const flatList = poopNamesMatrix.flat();

  // Shuffle the flatList array
  for (let i = flatList.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [flatList[i], flatList[j]] = [flatList[j], flatList[i]]; // ES6 destructuring assignment for swapping
  }
  const randomNumber = Math.floor(Math.random() * 999) + 1; // Random number between 1 and 999

  // Combine two random unique names from the shuffled list
  return flatList[0] + flatList[1] + randomNumber;
};

export function useNetworkStateSnackbar() {
  const networkState = useNetwork();
  const prevNetworkState = usePrevious(networkState);
  const { showToast } = useToast();

  useEffect(() => {
    if (prevNetworkState) {
      if (prevNetworkState.online && !networkState.online) {
        showToast("You are offline.", "error");
      }
      if (!prevNetworkState.online && networkState.online) {
        showToast("You're back online.", "success");
      }
    }
  }, [networkState, prevNetworkState]);
}
