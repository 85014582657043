import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { User } from "../firebase/types";

export interface UserState {
  loggedInUser: User | null;
}

const initialState: UserState = {
  loggedInUser: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedInUser(state, action) {
      state.loggedInUser = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

// export const { setLoggedInUser } = userSlice.actions;

// export const getLoggedInUser = (state: RootState) => state.user.loggedInUser;

export default userSlice.reducer;
