import { Avatar, Box, Stack, Typography } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { useUserService } from "../../../context/firebase/UserContext";
import StarIcon from "@mui/icons-material/Star";
import usePremiumUser from "../../../global/hooks/usePremiumUser";
import { useNavigate } from "react-router-dom";

const Leaderboard = () => {
  const navigate = useNavigate();
  const { mostFollowedUsers } = useUserService();
  const { isPremiumUser } = usePremiumUser();

  // Sort the users once and store the result
  const sortedUsers = mostFollowedUsers
    .filter((user) => user)
    .sort((a, b) => {
      // Handle undefined values for both streaks to move them to the end
      const currentStreakA = a.currentStreak ?? -Infinity;
      const currentStreakB = b.currentStreak ?? -Infinity;
      const longestStreakA = a.longestStreak ?? -Infinity;
      const longestStreakB = b.longestStreak ?? -Infinity;

      // Sort by current streak as primary and longest streak as secondary
      return currentStreakB - currentStreakA || longestStreakB - longestStreakA;
    });

  // Split the sorted array into current and longest streaks
  const sortedUsersByCurrentStreak = [...sortedUsers].sort(
    (a, b) => b.currentStreak - a.currentStreak
  );
  const sortedUsersByLongestStreak = [...sortedUsers].sort(
    (a, b) => b.longestStreak - a.longestStreak
  );

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        alignSelf={"center"}
        alignItems={"baseline"}
      >
        <ElectricBoltIcon color="warning" />
        <Typography textAlign={"center"} variant="h4">
          Leaderboard
        </Typography>
        <EmojiEventsIcon color="primary" />
      </Stack>
      <Typography textAlign={"center"} variant="body2" pb={2}>
        See who's on top 100 of the leaderboard!
      </Typography>

      <Box display="flex" height="100vh" width="100vw">
        {/* Best Current Streaks Section */}
        <Stack flex={1} overflow="auto" p={2} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h5">Best Current Streaks</Typography>
            <ElectricBoltIcon color="warning" />
          </Stack>

          {/* Add your current streaks content here */}
          <Stack spacing={1}>
            {/* Example items */}
            {sortedUsersByCurrentStreak.slice(0, 100).map((user, index) => (
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                key={index}
                onClick={() => navigate(`/root/user/${user.uid}`)}
              >
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                  }}
                  src={user?.photoURL}
                />
                <Stack alignItems={"flex-start"}>
                  <Stack direction="row" alignItems="center">
                    <Typography fontWeight={"bold"}>
                      {user?.username}
                    </Typography>
                    {isPremiumUser(user) && (
                      <StarIcon color={"warning"} fontSize={"small"} />
                    )}
                  </Stack>
                  <Typography variant={"caption"}>
                    {user.currentStreak ?? 0} - Current Streak
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* Best Longest Streaks Section */}
        <Stack flex={1} overflow="auto" p={2} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h5">Best Longest Streaks</Typography>
            <EmojiEventsIcon color="primary" />
          </Stack>
          {/* Add your longest streaks content here */}
          <Stack spacing={1}>
            {/* Example items */}
            {sortedUsersByLongestStreak.slice(0, 100).map((user, index) => (
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                key={index}
                onClick={() => navigate(`/root/user/${user.uid}`)}
              >
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                  }}
                  src={user?.photoURL}
                />
                <Stack alignItems={"flex-start"}>
                  <Stack direction="row" alignItems="center">
                    <Typography fontWeight={"bold"}>
                      {user?.username}
                    </Typography>
                    {isPremiumUser(user) && (
                      <StarIcon color={"warning"} fontSize={"small"} />
                    )}
                  </Stack>
                  <Typography variant={"caption"}>
                    {user.currentStreak ?? 0} - Longest Streak
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Leaderboard;
