import { useState, createContext, useContext, useEffect } from "react";
import {
  getDoc,
  doc,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInAnonymously,
  EmailAuthProvider,
  linkWithCredential,
  updateCurrentUser,
  UserCredential,
  UserInfo,
  linkWithPopup,
  signInWithCredential,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { formatISO } from "date-fns";
import {
  USER_COLLECTION,
  getRandomPoopName,
  isPasswordValid,
} from "../../global/utils";
import { User } from "../../firebase/types";
import { LoginForm, SignupForm } from "../../components/form/types";
import { auth } from "../../firebase/init";
import { useToast } from "../ToastContext";
import { useTheme } from "../ThemeContext";
import { useLoginContext } from "../LoginWallContext";

interface AuthServiceContextType {
  user?: User;
  linkWithEmailAndPass: (form: SignupForm) => void;
  // loginWithEmailAndPass: (form: LoginForm) => void;
  fetchLoggedInUser: (uid: string | undefined) => Promise<void>;
  logout: () => void;
  deleteAccount: () => void;
  updateFcmToken: (fcmToken: String) => void;
  linkWithGoogle: () => void;
  resetPassword: (email: string) => void;
  setUser: (user: User) => void;
  checkIfUsernameExists: (username: string) => Promise<boolean>;
  authenticateSilently: () => void;
}

const AuthServiceContext = createContext<AuthServiceContextType>({
  user: undefined,
  linkWithEmailAndPass: () => {},
  // loginWithEmailAndPass: () => {},
  fetchLoggedInUser: async () => {},
  logout: () => {},
  deleteAccount: () => {},
  updateFcmToken: () => {},
  linkWithGoogle: () => {},
  resetPassword: () => {},
  setUser: (user: User) => user,
  checkIfUsernameExists: async () => false,
  authenticateSilently: () => {},
});

export const useAuthService = () => useContext(AuthServiceContext);

export const AuthServiceProvider = ({ children }: any) => {
  const [user, setUser] = useState<User>();
  const { showToast } = useToast();
  const { setMode } = useTheme();
  const { setOpenLoginWall } = useLoginContext();

  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          await fetchLoggedInUser(result.user.uid);
          showToast("Successfully logged in with Google account", "success");
          setOpenLoginWall(false);
        }
      } catch (error: any) {
        showToast("Error handling redirect result", "error");
        console.error("Error handling redirect result:", error);
      }
    };

    handleRedirectResult();
  }, []);

  const fetchLoggedInUser = async (uid: string | undefined) => {
    let docRef = doc(USER_COLLECTION, uid);
    let docSnap = await getDoc(docRef);
    setUser(docSnap.data() as User);
    setMode(docSnap.data()?.darkMode ? "dark" : "light");
  };

  const setupNewUser = async (res: any, username: string | null) => {
    const docRef = doc(USER_COLLECTION, res.user.uid);
    const docSnap = await getDoc(docRef);
    const exists = docSnap.exists();
    if (!exists) {
      try {
        let user = {
          email: res.user.email ?? "",
          username: username,
          username_searchable: username?.toLowerCase()?.trim(),
          uid: res?.user?.uid,
          createdAt: formatISO(new Date()),
          updatedAt: formatISO(new Date()),
          photoURL: "",
          premium: false,
          followers: 0,
          followings: 0,
          points: {
            accumulatedPoints: 0,
            currentPoints: 300,
          },
        };
        await setDoc(docRef, user);
        setUser(user as unknown as User);
      } catch (error) {}
    } else {
      setUser(docSnap.data() as User);
    }
  };

  // const loginWithEmailAndPass = async ({ email, password }: LoginForm) => {
  //   try {
  //     await signInWithEmailAndPassword(
  //       auth,
  //       email.trim().toLowerCase(),
  //       password.trim()
  //     );
  //   } catch (err: any) {
  //     switch (err.code) {
  //       case "auth/wrong-password":
  //         showToast("Wrong password.", "error");
  //         break;
  //       case "auth/user-not-found":
  //         showToast("Email not found.", "error");
  //         break;
  //       case "auth/invalid-email":
  //         showToast("Invalid email.", "error");
  //         break;
  //       case "auth/invalid-credential":
  //         showToast("Invalid credentials.", "error");
  //         break;
  //       case "auth/too-many-requests":
  //         showToast("Too many requests, try again later.", "error");
  //         break;
  //     }
  //   }
  //   return true;
  // };

  const checkIfEmailExists = async (email: string) => {
    const q = query(
      USER_COLLECTION,
      where("email", "==", email?.toLowerCase()?.trim())
    );
    let documentSnapshots = await getDocs(q);
    let documentData: User[] = documentSnapshots.docs.map(
      (document) => document.data() as User
    );
    if (documentData.length > 0) {
      return true;
    }
    return false;
  };

  const checkIfUsernameExists = async (username: string) => {
    const q = query(
      USER_COLLECTION,
      where("username_searchable", "==", username?.toLowerCase()?.trim())
    );
    let documentSnapshots = await getDocs(q);
    let documentData: User[] = documentSnapshots.docs.map(
      (document) => document.data() as User
    );
    if (documentData.length > 0) {
      return true;
    }
    return false;
  };

  const linkWithGoogle = async () => {
    try {
      const result: UserCredential = await signInWithPopup(auth, provider);
      await setupNewUser(result, getRandomPoopName());
      setOpenLoginWall(false);
      showToast("Successfully logged in", "success");
    } catch (error: any) {
      showToast("Error linking to Google account", "error");
      console.error("Error linking to Google account:", error);
    }
  };

  const linkWithEmailAndPass = async ({ email, password }: SignupForm) => {
    console.log("linkWithEmailAndPass");
    if (!email || email?.length < 5) {
      showToast("Please enter a valid email", "warning");
      return;
    }
    if (!password || !isPasswordValid(password)) {
      showToast("Password must contain at least 6 characters", "warning");
      return;
    }
    let emailExists = await checkIfEmailExists(email);
    if (emailExists) {
      loginWithEmailAndPassword(email, password);
    } else {
      signupWithEmailAndPassword(email, password);
    }
  };

  const signupWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      let res = await createUserWithEmailAndPassword(
        auth,
        email.trim().toLowerCase(),
        password.trim()
      );
      await setupNewUser(res, getRandomPoopName());
      setOpenLoginWall(false);
    } catch (err: any) {
      switch (err.code) {
        case "auth/wrong-password":
          showToast("Wrong password.", "error");
          break;
        case "auth/user-not-found":
          showToast("Email not found.", "error");
          break;
        case "auth/invalid-email":
          showToast("Invalid email.", "error");
          break;
        case "auth/invalid-credential":
          showToast(
            "Invalid credentials. This email is already being used",
            "error"
          );
          break;
        case "auth/too-many-requests":
          showToast("Too many requests, try again later.", "error");
          break;
      }
    }
  };

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      let res = await signInWithEmailAndPassword(
        auth,
        email.trim().toLowerCase(),
        password.trim()
      );
      await setupNewUser(res, getRandomPoopName());
      setOpenLoginWall(false);
    } catch (err: any) {
      switch (err.code) {
        case "auth/wrong-password":
          showToast("Wrong password.", "error");
          break;
        case "auth/user-not-found":
          showToast("Email not found.", "error");
          break;
        case "auth/invalid-email":
          showToast("Invalid email.", "error");
          break;
        case "auth/invalid-credential":
          showToast("Invalid credentials.", "error");
          break;
        case "auth/too-many-requests":
          showToast("Too many requests, try again later.", "error");
          break;
      }
    }
  };

  const updateCurrentUser = async (userInfo: UserInfo) => {
    const docRef = doc(USER_COLLECTION, userInfo.uid);
    const docSnap = await getDoc(docRef);
    const exists = docSnap.exists();
    if (exists && userInfo.email) {
      try {
        await updateDoc(docRef, { email: userInfo.email });
        let updatedUser = { ...user };
        updatedUser.email = userInfo.email as string;
        setUser(updatedUser as User);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const authenticateSilently = async () => {
    const res = await signInAnonymously(auth);
    setupNewUser(res, getRandomPoopName());
  };

  const deleteAccount = async () => {
    deleteDoc(doc(USER_COLLECTION, auth?.currentUser?.uid));
    await logout();
  };

  const logout = async () => {
    await auth.signOut();
  };

  const updateFcmToken = async (fcmToken: String) => {
    if (!user) return;
    const docRef = doc(USER_COLLECTION, user?.uid);
    await setDoc(docRef, {
      ...user,
      fcmToken,
      updatedAt: formatISO(new Date()),
      enableNotifications: true,
    });
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (e: any) {
      return e;
    }
  };

  const value = {
    user,
    setUser,
    linkWithEmailAndPass,
    // loginWithEmailAndPass,
    fetchLoggedInUser,
    logout,
    deleteAccount,
    updateFcmToken,
    linkWithGoogle,
    resetPassword,
    checkIfUsernameExists,
    authenticateSilently,
  };

  return (
    <AuthServiceContext.Provider value={value}>
      {children}
    </AuthServiceContext.Provider>
  );
};
