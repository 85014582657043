import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";

interface CustomDialogProps {
  title: string | React.ReactNode;
  topRightActionButtons?: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  setOpen: Function;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullScreen?: boolean;
  zIndex?: number;
  keepMounted?: boolean;
  hasHeader?: boolean;
}

const CustomDialog = ({
  title = null,
  topRightActionButtons = null,
  children = null,
  open = false,
  setOpen = () => {},
  maxWidth = "sm",
  fullScreen = false,
  zIndex = undefined,
  keepMounted = false,
  hasHeader = true,
}: CustomDialogProps) => {
  const theme = useTheme();

  useEffect(() => {
    setOpen(open);
  }, [open, setOpen]);

  const handleClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <Dialog
      sx={{
        zIndex: zIndex ?? theme.zIndex.modal,
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      fullWidth
      keepMounted={keepMounted}
      maxWidth={maxWidth}
    >
      {hasHeader && (
        <>
          <DialogTitle>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {title}
              <IconButton onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <Divider />
        </>
      )}

      {children}
    </Dialog>
  );
};

export default CustomDialog;
