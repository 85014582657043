import { Navigate } from "react-router-dom";
import { useAuthService } from "../context/firebase/AuthContext";
import { useEffect, useState } from "react";
import { auth } from "../firebase/init";

const AuthenticatedRoute = ({ children }: any) => {
  const { user, fetchLoggedInUser } = useAuthService();
  const [autheticated, setAuthenticated] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (!user) {
      const verifyAppInstallation = async () => {
        const isPWAInstalled = await checkIfPWAInstalled();
        if (isPWAInstalled) {
          auth.onAuthStateChanged(authStateChanged);
        } else {
          setAuthenticated(false);
        }
      };
      // auth.onAuthStateChanged(authStateChanged);
      // verifyAppInstallation();
      if (window.location.hostname === "localhost") {
        auth.onAuthStateChanged(authStateChanged);
      } else {
        verifyAppInstallation();
      }
    } else {
      setAuthenticated(true);
    }
  }, []);

  const checkIfPWAInstalled = async () => {
    let isInstalled = false;

    // ANDROID
    if ("getInstalledRelatedApps" in navigator) {
      //@ts-ignore
      const relatedApps = await navigator?.getInstalledRelatedApps();
      isInstalled = relatedApps.length > 0;
    }

    if (!isInstalled) {
      // IOS and SAFARI
      if ("standalone" in window.navigator && window.navigator.standalone) {
        isInstalled = true;
        // ANDROID ALL and MOST IOS (chrome might be buggy)
      } else if (window.matchMedia("(display-mode: standalone)").matches) {
        isInstalled = true;
      }
    }

    return isInstalled;
  };

  const authStateChanged = async (user: any) => {
    if (user) {
      setAuthenticated(true);
      fetchLoggedInUser(user.uid);
    } else {
      setAuthenticated(false);
    }
  };

  if (autheticated === undefined) return null;

  return autheticated ? children : <Navigate to="/" replace />;
};

export default AuthenticatedRoute;
