import { createContext, useContext, useState } from "react";
import Toast from "../components/Toast";

interface ToastContextType {
  showToast: (
    message: string,
    type?: "success" | "error" | "warning" | "info",
    bottom?: number
  ) => void;
}

const ToastContext = createContext<ToastContextType>({
  showToast: () => {},
});

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: any) => {
  const [toast, setToast] = useState({
    open: false,
    message: "",
    type: "",
    bottom: 80,
  });

  const showToast = (message: string, type = "info", bottom = 80) => {
    setToast({ open: true, message, type, bottom });
    setTimeout(
      () => setToast({ open: false, message: "", type: "", bottom }),
      3000
    ); // auto-hide after 3 seconds
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {/* Render your Toast component here if it's open */}
      {toast.open && (
        <Toast
          message={toast.message}
          //@ts-ignore
          type={toast.type}
          bottom={toast.bottom}
        />
      )}
    </ToastContext.Provider>
  );
};
