import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Suspense, lazy } from "react";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { useNetworkStateSnackbar } from "../global/utils";
import { Alert, Box } from "@mui/material";
import HomeHeader from "../pages/root/home/components/HomeHeader";
import Leaderboard from "../pages/root/home/Leaderboard";

const poopBouncing = require("../assets/poopBouncing.gif");

const Splash = lazy(() => import("../routes/Splash"));
const Root = lazy(() => import("../routes/Root"));
const HomeScreen = lazy(() => import("../pages/root/home/HomeScreen"));
const Notifications = lazy(() => import("../pages/root/home/Notifications"));
const Chat = lazy(() => import("../pages/root/home/Chat"));
const PostCreate = lazy(() => import("../pages/root/create/PostCreate"));
const GoogleMaps = lazy(() => import("../pages/root/map/GoogleMaps"));
const AccountEdit = lazy(
  () => import("../pages/root/account/components/AccountEdit")
);
const Find = lazy(() => import("../pages/root/find/Find"));
const Welcome = lazy(() => import("../pages/onboarding/Welcome"));
const OnboardPoop = lazy(() => import("../pages/onboarding/OnboardPoop"));
const OnboardSnap = lazy(() => import("../pages/onboarding/OnboardSnap"));
const OnboardPost = lazy(() => import("../pages/onboarding/OnboardPost"));
const PoopPage = lazy(() => import("../pages/root/components/PoopPage"));
const Premium = lazy(() => import("../pages/root/premium/Premium"));
const UserAccountViewOnly = lazy(
  () => import("../pages/root/find/components/UserAccountViewOnly")
);
const NeedHelp = lazy(
  () => import("../pages/root/account/components/NeedHelp")
);
const AccountFollowers = lazy(
  () => import("../pages/root/account/components/AccountFollowers")
);
const AccountFollowings = lazy(
  () => import("../pages/root/account/components/AccountFollowings")
);
const AccountAdminTypePicker = lazy(
  () => import("../pages/root/account/components/admin/AccountAdminTypePicker")
);
const AccountAdminReport = lazy(
  () => import("../pages/root/account/components/admin/AccountAdminReport")
);
const AccountActivity = lazy(
  () => import("../pages/root/account/components/activity/AccountActivity")
);
const PremiumConfirmation = lazy(
  () => import("../pages/root/premium/PremiumConfirmation")
);
const InstallOrContinue = lazy(
  () => import("../pages/onboarding/InstallOrContinue")
);
const Account = lazy(() => import("../pages/root/account/Account"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense
        fallback={
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={poopBouncing} height={50} width={50} />
          </Box>
        }
      >
        <Splash />
      </Suspense>
    ),
    errorElement: <h1>Not Found</h1>,
  },
  {
    path: "/root",
    element: (
      <AuthenticatedRoute>
        <Suspense
          fallback={
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={poopBouncing} height={50} width={50} />
            </Box>
          }
        >
          <Root />
        </Suspense>
      </AuthenticatedRoute>
    ),
    errorElement: <h1>Not Found</h1>,
    children: [
      {
        path: "",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <HomeHeader />
            <HomeScreen />
          </Suspense>
        ),
      },
      {
        path: "chat",
        element: (
          <AuthenticatedRoute>
            <Suspense
              fallback={
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={poopBouncing} height={50} width={50} />
                </Box>
              }
            >
              <Chat />
            </Suspense>
          </AuthenticatedRoute>
        ),
      },
      {
        path: "leaderboard",
        element: (
          <AuthenticatedRoute>
            <Suspense
              fallback={
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={poopBouncing} height={50} width={50} />
                </Box>
              }
            >
              <Leaderboard />
            </Suspense>
          </AuthenticatedRoute>
        ),
      },
      {
        path: "notifications",
        element: (
          <AuthenticatedRoute>
            <Suspense
              fallback={
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={poopBouncing} height={50} width={50} />
                </Box>
              }
            >
              <Notifications />
            </Suspense>
          </AuthenticatedRoute>
        ),
      },
      {
        path: "poop/:id",
        element: (
          <AuthenticatedRoute>
            <Suspense
              fallback={
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={poopBouncing} height={50} width={50} />
                </Box>
              }
            >
              <PoopPage />
            </Suspense>
          </AuthenticatedRoute>
        ),
      },
      {
        path: "map",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <GoogleMaps />
          </Suspense>
        ),
      },
      {
        path: "premium",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <Premium />
          </Suspense>
        ),
      },
      {
        path: "premium-confirmation",
        element: (
          <AuthenticatedRoute>
            <Suspense
              fallback={
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={poopBouncing} height={50} width={50} />
                </Box>
              }
            >
              <PremiumConfirmation />
            </Suspense>
          </AuthenticatedRoute>
        ),
      },
      {
        path: "create",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <PostCreate />
          </Suspense>
        ),
      },
      {
        path: "find",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <Find />
          </Suspense>
        ),
      },
      {
        path: "user/:uid",
        element: (
          <AuthenticatedRoute>
            <Suspense
              fallback={
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={poopBouncing} height={50} width={50} />
                </Box>
              }
            >
              <UserAccountViewOnly />
            </Suspense>
          </AuthenticatedRoute>
        ),
      },
      {
        path: "account",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <Account />
          </Suspense>
        ),
      },

      {
        path: "account/edit",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} alt="poop" />
              </Box>
            }
          >
            <AccountEdit />
          </Suspense>
        ),
      },
      {
        path: "account/help",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <NeedHelp />
          </Suspense>
        ),
      },
      {
        path: "account/followers/:uid",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <AccountFollowers />
          </Suspense>
        ),
      },
      {
        path: "account/followings/:uid",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <AccountFollowings />
          </Suspense>
        ),
      },
      {
        path: "account/admin/type",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <AccountAdminTypePicker />
          </Suspense>
        ),
      },
      {
        path: "account/admin/report",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <AccountAdminReport />
          </Suspense>
        ),
      },
      {
        path: "account/activity",
        element: (
          <Suspense
            fallback={
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={poopBouncing} height={50} width={50} />
              </Box>
            }
          >
            <AccountActivity />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/onboard",
    element: (
      <Suspense
        fallback={
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={poopBouncing} height={50} width={50} />
          </Box>
        }
      >
        <Welcome />
      </Suspense>
    ),
    errorElement: <h1>Not Found</h1>,
  },
  {
    path: "/onboardPoop",
    element: (
      <Suspense
        fallback={
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={poopBouncing} height={50} width={50} />
          </Box>
        }
      >
        <OnboardPoop />
      </Suspense>
    ),
    errorElement: <h1>Not Found</h1>,
  },
  {
    path: "/onboardSnap",
    element: (
      <Suspense
        fallback={
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={poopBouncing} height={50} width={50} />
          </Box>
        }
      >
        <OnboardSnap />
      </Suspense>
    ),
    errorElement: <h1>Not Found</h1>,
  },
  {
    path: "/onboardPost",
    element: (
      <Suspense
        fallback={
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={poopBouncing} height={50} width={50} />
          </Box>
        }
      >
        <OnboardPost />
      </Suspense>
    ),
    errorElement: <h1>Not Found</h1>,
  },
  {
    path: "/instructions",
    element: (
      <Suspense
        fallback={
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={poopBouncing} height={50} width={50} />
          </Box>
        }
      >
        <InstallOrContinue />
      </Suspense>
    ),
    errorElement: <h1>Not Found</h1>,
  },
]);

const RoutesNavigator = () => {
  useNetworkStateSnackbar();

  return <RouterProvider router={router} />;
};

export default RoutesNavigator;
