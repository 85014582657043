import { createContext, useContext, useEffect, useState } from "react";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { theme } from "../global/theme";

interface ThemeContextType {
  toggleTheme: () => void;
  setMode: (mode: "light" | "dark") => void;
  mode: string;
}

const ThemeContext = createContext<ThemeContextType>({
  toggleTheme: () => {},
  setMode: () => {},
  mode: "light",
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }: any) => {
  const [mode, setMode] = useState("light");

  useEffect(() => {
    // Set the initial status bar style
    updateStatusBarStyle(mode);
  }, [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const updateStatusBarStyle = (mode: string) => {
    const themeColorMetaTag = document.querySelector(
      'meta[name="theme-color"]'
    );
    const appleStatusBarMetaTag = document.querySelector(
      'meta[name="apple-mobile-web-app-status-bar-style"]'
    );

    if (mode === "dark") {
      themeColorMetaTag?.setAttribute("content", "#000000"); // Dark mode background color
      appleStatusBarMetaTag?.setAttribute("content", "black-translucent"); // Dark mode status bar style
    } else {
      themeColorMetaTag?.setAttribute("content", "#ffffff"); // Light mode background color
      appleStatusBarMetaTag?.setAttribute("content", "default"); // Light mode status bar style
    }
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme, setMode }}>
      <MUIThemeProvider theme={theme(mode)}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
