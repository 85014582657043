import { doc, collection, getDoc } from "firebase/firestore";
import { createContext, useContext } from "react";
import user from "../../redux/user";
import { firestore } from "../../firebase/init";

interface ReportsServiceContextType {
  getUsersReport: () => Promise<{
    totalUsersYesterday: number;
    usersWithEmailYesterday: number;
  } | null>;
}

const ReportsServiceContext = createContext<ReportsServiceContextType>({
  getUsersReport: async () => {
    return {
      totalUsersYesterday: 0,
      usersWithEmailYesterday: 0,
    };
  },
});

export const useReportsService = () => useContext(ReportsServiceContext);

export const ReportsServiceProvider = ({ children }: any) => {
  const getUsersReport = async () => {
    try {
      const userReportRef = doc(collection(firestore, "reports"), "userReport");
      const userReportSnapshot = await getDoc(userReportRef);
      if (userReportSnapshot.exists()) {
        const data = userReportSnapshot.data();
        return {
          totalUsersYesterday: data.totalUsers,
          usersWithEmailYesterday: data.usersWithEmail,
        };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting document: ", error);
      return null;
    }
  };

  const value = { getUsersReport };

  return (
    <ReportsServiceContext.Provider value={value}>
      {children}
    </ReportsServiceContext.Provider>
  );
};
