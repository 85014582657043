import { Provider } from "react-redux";
import RoutesNavigator from "./navigation/RoutesNavigator";
import { Box, Container, SwipeableDrawer } from "@mui/material";
import { store } from "./redux/store";
import { PoopServiceProvider } from "./context/firebase/PoopContext";
import { UserServiceProvider } from "./context/firebase/UserContext";
import { LocationServiceProvider } from "./context/LocationContext";
import { AuthServiceProvider } from "./context/firebase/AuthContext";
import { ToastProvider } from "./context/ToastContext";
import { ThemeProvider } from "./context/ThemeContext";
import { useNetworkStateSnackbar } from "./global/utils";
import { SyntheticEvent } from "react";
import { LoginWallProvider } from "./context/LoginWallContext";
import { ReportsServiceProvider } from "./context/firebase/ReportsContext";
import { NotificationsProvider } from "./context/firebase/NotificationsContext";

function App() {
  return (
    <ThemeProvider>
      {/* Pass the mode to the theme */}
      <Provider store={store}>
        <ToastProvider>
          <LoginWallProvider>
            <AuthServiceProvider>
              <PoopServiceProvider>
                <UserServiceProvider>
                  <NotificationsProvider>
                    <ReportsServiceProvider>
                      <LocationServiceProvider>
                        <Container>
                          <Box
                            minHeight="90vh"
                            paddingBottom={"10vh"}
                            paddingTop="2vh"
                            display="flex"
                            flex={1}
                          >
                            <RoutesNavigator />
                          </Box>
                        </Container>
                      </LocationServiceProvider>
                    </ReportsServiceProvider>
                  </NotificationsProvider>
                </UserServiceProvider>
              </PoopServiceProvider>
            </AuthServiceProvider>
          </LoginWallProvider>
        </ToastProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
